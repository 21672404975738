import { css } from '@emotion/react'
import { exclusionsDimension, type LooselyTypedData } from '@orus.eu/dimensions'
import {
  Divider,
  LayoutTabbed,
  NotFoundError,
  PersistentNotification,
  Section,
  Tab,
  spacing,
  useAsyncCallback,
  useCrash,
  useDialogVisibility,
} from '@orus.eu/pharaoh'
import { LanguageProvider } from '@orus.eu/pharaoh/src/localization/language-context'
import { useBlocker, useParams } from '@tanstack/react-router'
import { Fragment, memo, useCallback, useEffect, useRef, useState, type FormEvent, type ReactNode } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { assert } from '../../../../lib/errors'
import { noop } from '../../../../lib/noop'
import { usePermissions } from '../../../../lib/use-permissions'
import { useBuildUrl } from '../../../../use-build-url'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { PreventUpdatingContractDialog } from '../../../organisms/prevent-updating-contract-dialog'
import { UnsavedChangesDialog } from '../../../organisms/unsaved-changes-dialog'
import { useModularNextEnabled } from '../../subscription-v2/modular-next-enabled'
import { SubscriptionBackofficeElementGroupBlock } from '../../subscription-v2/subscription-v2-element-group-block'
import { ExclusionsDimensionField } from './backoffice-quote-editor-v2/fields/exclusions-dimension-field'
import { QuoteEditorV2Header } from './backoffice-quote-editor-v2/quote-editor-v2-header'
import { QuoteEditorV2SidePanel } from './backoffice-quote-editor-v2/quote-editor-v2-side-panel'
import { useQuoteEditorState } from './backoffice-quote-editor-v2/quote-editor-v2-state'
import { getQuoteEditorUiTabs } from './backoffice-quote-editor-v2/sections/quote-editor-sections.util'
import { useRequiredDimensionsHighlights } from './use-required-dimensions-highlights'

const PendingSubscriptionPage = memo(function PendingSubscriptionPage(): ReactNode {
  const { subscriptionId, organization } = useParams({ strict: false })
  const { buildUrl } = useBuildUrl()
  const crash = useCrash()
  assert(subscriptionId, 'missing subscriptionId url param')
  const { permissions, type } = usePermissions()
  const isPartner = type === 'partner'

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true)

  const { isLoading: isSubscriptionPartOfOrganizationLoading, data: isSubscriptionPartOfOrganization } =
    trpcReact.subscriptions.isSubscriptionPartOfOrganization.useQuery(
      { subscriptionId, organization: organization! },
      { enabled: !!organization && isPartner },
    )

  const { isLoading: isSubscriptionOrganizationTypeLoading, data: subscriptionOrganizationType } =
    trpcReact.organizations.getOrganizationTypeForSubscriptionId.useQuery(subscriptionId)

  const { isLoading: isSubscriptionAccessibleProductsLoading } = trpcReact.subscriptions.getAccessibleProducts.useQuery(
    organization!,
    { enabled: !!organization && isPartner },
  )

  const {
    changes,
    setChanges,
    before,
    after,
    nonDimensionalDataAfter,
    reset,
    commit,
    commiting,
    stateChangeNotifications,
  } = useQuoteEditorState({ subscriptionId })

  const { nextEnabledByAllContributions, registerNextEnabledContribution } = useModularNextEnabled()

  /**
   * Compute the displayed state by displaying the freshest value available
   */
  const state = Object.assign(
    {},
    // Start with the complete state computed on the server
    after,
    // Overriden by uncommited changes to the inputs
    changes,
  )

  const quoteEditorUiTabs = getQuoteEditorUiTabs({
    state,
    permissions,
    isPartner,
    subscriptionOrganizationType,
    isContractSigned: !!nonDimensionalDataAfter?.contract,
  })
  const tabs = [...quoteEditorUiTabs.entries()].map(([name, _]) => name)

  const [tabsMissingDimensions, setTabsMissingDimensions] = useState(tabs.map(() => new Set<string>()))
  const cleanTabsMissingDimensions = useCallback(() => {
    setTabsMissingDimensions(tabs.map(() => new Set<string>()))
  }, [tabs])

  const {
    show: showPreventUpdatingContractDialog,
    hide: hidePreventUpdatingContractDialog,
    visible: isPreventUpdatingContractDialogVisible,
  } = useDialogVisibility('prevent-updating-contract')
  const {
    show: showUnsavedChangesDialog,
    hide: hideUnsavedChangesDialog,
    visible: isUnsavedChangesDialogVisible,
  } = useDialogVisibility('unsaved-changes')

  const handleAddMissingDimension = useCallback(
    (tabIndex: number, dimensionName: string) => {
      if (tabsMissingDimensions[tabIndex]?.has(dimensionName)) return
      setTabsMissingDimensions((prev) => {
        const newTabsRequiringFields = [...prev]
        newTabsRequiringFields[tabIndex].add(dimensionName)
        return newTabsRequiringFields
      })
    },
    [tabsMissingDimensions, setTabsMissingDimensions],
  )

  const handleRemoveMissingDimension = useCallback(
    (tabIndex: number, dimensionName: string) => {
      if (!tabsMissingDimensions[tabIndex]?.has(dimensionName)) return

      setTabsMissingDimensions((prev) => {
        const newTabsRequiringFields = [...prev]
        newTabsRequiringFields[tabIndex].delete(dimensionName)
        return newTabsRequiringFields
      })
    },
    [tabsMissingDimensions, setTabsMissingDimensions],
  )

  const {
    toggleHighlightDimensionsReason,
    highlightDimensionsNames,
    highlightDimensionsByReason,
    requiredDimensionsNames,
  } = useRequiredDimensionsHighlights({
    state,
    subscriptionId,
    cleanTabsMissingDimensions,
    nonDimensionalDataAfter,
  })

  const handleSubmit = useCallback(
    (_event?: FormEvent, changes?: LooselyTypedData) => {
      if (changes) setChanges(changes)
    },
    [setChanges],
  )

  const tabsBreadcrumbsLength = tabs.reduce(
    (previous, curr) => {
      previous[curr] = quoteEditorUiTabs.get(curr)!.size
      return previous
    },
    {} as Record<string, number>,
  )

  const setSubscriptionOwner = useAsyncCallback(
    async (newCustomerId: string) => {
      await trpc.subscriptions.setSubscriptionOwner.mutate({ subscriptionId, customerId: newCustomerId })
      setChanges({})
    },
    [setChanges, subscriptionId],
  )

  const shouldBypassBrowserConfirmationDialog = useRef(false)

  const bypassBrowserConfirmationDialog = useCallback(() => (shouldBypassBrowserConfirmationDialog.current = true), [])

  const isUnsavedChanges = Object.keys(changes).length > 0

  const {
    proceed: blockerProceed,
    reset: blockerReset,
    status: blockerStatus,
  } = useBlocker({
    condition: isUnsavedChanges && !shouldBypassBrowserConfirmationDialog.current,
  })

  useEffect(() => {
    if (blockerStatus === 'blocked') {
      showUnsavedChangesDialog()
    }
  }, [blockerStatus, showUnsavedChangesDialog])

  const proceedUnsavedChanges = () => {
    blockerProceed()
    hideUnsavedChangesDialog()
  }

  const resetUnsavedChanges = () => {
    blockerReset()
    hideUnsavedChangesDialog()
  }

  const getTabVariant = useCallback(
    (tabIndex: number) => (tabsMissingDimensions[tabIndex]?.size > 0 ? 'danger' : 'neutral'),
    [tabsMissingDimensions],
  )

  useEffect(() => {
    const shouldShowPreventUpdatingContractDialog = stateChangeNotifications.some((message) =>
      [
        'contract_products_not_matching_new_activity',
        'at_least_one_product_required',
        'product_selection_contract_mismatch',
      ].includes(message),
    )
    if (shouldShowPreventUpdatingContractDialog) showPreventUpdatingContractDialog()
  }, [stateChangeNotifications, showPreventUpdatingContractDialog])

  if (isPartner) {
    if (isSubscriptionPartOfOrganization === false) {
      crash(new NotFoundError())
      return <GlobalLoadingState />
    }
  }

  if (
    !before ||
    !nonDimensionalDataAfter ||
    (isPartner &&
      (isSubscriptionPartOfOrganizationLoading ||
        isSubscriptionAccessibleProductsLoading ||
        isSubscriptionOrganizationTypeLoading))
  )
    return <GlobalLoadingState />

  const currentEmail = changes.email || before.email || undefined
  const currentPhone = changes.phone || before.phone || undefined

  const wrapperCss = css`
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: filter 0.3s;
    display: flex;
    flex-direction: column;
  `

  return (
    <div
      className="nodash"
      css={
        commiting
          ? css`
              filter: opacity(0.5);
              pointer-events: none;
              user-select: none;
              ${wrapperCss}
            `
          : wrapperCss
      }
    >
      <LanguageProvider value={state.operatingZone ?? undefined}>
        {isPreventUpdatingContractDialogVisible ? (
          <PreventUpdatingContractDialog hide={hidePreventUpdatingContractDialog} />
        ) : null}
        {isUnsavedChangesDialogVisible ? (
          <UnsavedChangesDialog reset={resetUnsavedChanges} proceed={proceedUnsavedChanges} />
        ) : null}
        <LayoutTabbed
          header={
            <>
              <QuoteEditorV2Header
                subscriptionId={subscriptionId}
                changes={changes}
                onReset={reset}
                onValidate={commit}
                nonDimensionalDataAfter={nonDimensionalDataAfter}
                hasQuote={!!after?.quote}
                currentEmail={currentEmail}
                currentPhone={currentPhone}
                nextEnabledByAllContributions={nextEnabledByAllContributions}
                bypassBrowserConfirmationDialog={bypassBrowserConfirmationDialog}
              />
              <ExclusionsDimensionField valueDimension={exclusionsDimension} state={state} />
              {nonDimensionalDataAfter.contract && isPartner ? (
                <div
                  css={css`
                    margin: ${spacing[60]} 0;
                    width: 100%;
                  `}
                >
                  <PersistentNotification variant="warning" title="Contrat en cours">
                    Veuillez contacter le support Orus pour émettre un avenant sur ce contrat.
                  </PersistentNotification>
                </div>
              ) : (
                <></>
              )}
            </>
          }
          sidePanel={
            <QuoteEditorV2SidePanel
              state={state}
              stateBefore={before}
              stateAfter={after}
              setChanges={setChanges}
              toggleHighlightDimensionsReason={toggleHighlightDimensionsReason}
              highlightDimensionsByReason={highlightDimensionsByReason}
              toggleIsSidePanelOpen={() => setIsSidePanelOpen(!isSidePanelOpen)}
              isSidePanelOpen={isSidePanelOpen}
              changes={changes}
              hasQuote={!!after?.quote}
              currentEmail={currentEmail}
              currentPhone={currentPhone}
              signatureUrl={
                isPartner
                  ? buildUrl({
                      to: '/partner/$organization/pending-subscriptions/$subscriptionId/payment',
                      params: { subscriptionId, organization: organization! },
                    })
                  : buildUrl({ to: '/bak/pending-subscriptions/$subscriptionId/payment', params: { subscriptionId } })
              }
              subscriptionId={subscriptionId}
              nonDimensionalDataAfter={nonDimensionalDataAfter}
            />
          }
          isSidePanelOpen={isSidePanelOpen}
          tabs={tabs.map((tab, tabIndex) => {
            return {
              tab: (
                <Tab key={tab} variant={getTabVariant(tabIndex)}>
                  {tab}
                </Tab>
              ),
              content: (
                <>
                  {[...quoteEditorUiTabs.get(tab)!.entries()].map(([breadcrumb, sections], index) => {
                    return (
                      <Fragment key={breadcrumb}>
                        <Section title={{ title: breadcrumb }}>
                          {sections.map((section) => (
                            <SubscriptionBackofficeElementGroupBlock
                              key={section.id}
                              context="backoffice"
                              customerId={nonDimensionalDataAfter.customerId}
                              contract={nonDimensionalDataAfter.contract}
                              group={section.bodyElements}
                              subscriptionId={subscriptionId}
                              stepId={undefined}
                              versionedSubscriptionId={`${subscriptionId}/0`}
                              localState={state}
                              updateLocalChanges={setChanges}
                              updateAndPersistLocalChanges={setChanges}
                              handleSubmit={handleSubmit}
                              registerNextEnabledContribution={registerNextEnabledContribution}
                              nextEnabled={false}
                              serverValidationIssue={null}
                              goBackToPreviousStep={noop}
                              goBackToStepRoot={noop}
                              goBackToBreadcrumbRootStep={noop}
                              synchronizing={false}
                              setSubscriptionOwner={setSubscriptionOwner}
                              changes={changes}
                              backofficeStepTitle={section.backofficeUi.title}
                              backofficeStepSubtitle={section.backofficeUi.subtitle}
                              requiredDimensionsNames={requiredDimensionsNames}
                              highlightDimensionsNames={highlightDimensionsNames}
                              addMissingDimension={(dimensionName: string) =>
                                handleAddMissingDimension(tabIndex, dimensionName)
                              }
                              removeMissingDimension={(dimensionName: string) =>
                                handleRemoveMissingDimension(tabIndex, dimensionName)
                              }
                            />
                          ))}
                        </Section>
                        {index !== tabsBreadcrumbsLength[tab] - 1 && (
                          <Divider
                            orientation="horizontal"
                            css={css`
                              margin-top: ${spacing[60]};
                              margin-bottom: ${spacing[60]};
                            `}
                          />
                        )}
                      </Fragment>
                    )
                  })}
                </>
              ),
            }
          })}
        />
      </LanguageProvider>
    </div>
  )
})
export default PendingSubscriptionPage
