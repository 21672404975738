import { css } from '@emotion/react'
import type { SubscriptionNonDimensionalData } from '@orus.eu/backend/src/routers/pending-subscriptions'
import type { AbstractDimension, PartialDimensionnedState } from '@orus.eu/dimensions'
import { Badge, Button } from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { useNavigateTo } from '../../../../../lib/hooks/use-navigate-to-route'
import { typedMemo } from '../../../../../lib/ts-utils'
import { usePermissions } from '../../../../../lib/use-permissions'
import { QuoteEditorV2ButtonBar } from './quote-editor-v2-button-bar'

export type QuoteEditorV2HeaderProps<Dimensions extends readonly AbstractDimension[]> = {
  subscriptionId: string
  nonDimensionalDataAfter: SubscriptionNonDimensionalData
  changes: PartialDimensionnedState<Dimensions>
  hasQuote: boolean
  onReset: () => void
  onValidate: () => void
  currentEmail: string | undefined
  currentPhone: string | undefined
  nextEnabledByAllContributions: boolean
  bypassBrowserConfirmationDialog: () => void
}

export const QuoteEditorV2Header = typedMemo(function QuoteEditorV2Header<
  Dimensions extends readonly AbstractDimension[],
>(props: QuoteEditorV2HeaderProps<Dimensions>) {
  const { type: userType } = usePermissions()
  const isPartner = userType === 'partner'
  const { nonDimensionalDataAfter } = props
  const { contract } = nonDimensionalDataAfter
  const navigateToBackofficeHome = useNavigateTo({ to: '/bak/home' })

  const badges = (
    <>
      {contract ? undefined : <Badge variant="blue">En cours</Badge>}
      {nonDimensionalDataAfter.hasEndorsementInProgress && !isPartner ? (
        <Badge variant="orange">Avenant en cours</Badge>
      ) : undefined}
      {nonDimensionalDataAfter.hasDocumentsLockConfirmed && !nonDimensionalDataAfter.hasDocumentsValidated ? (
        <Badge variant="red">Documents en attente</Badge>
      ) : undefined}
    </>
  )

  return (
    <Header
      css={css`
        /* hide while loading, but using visibility and non empty text to avoid ui flickering */
        visibility: ${props.nonDimensionalDataAfter ? undefined : 'hidden'};
      `}
      title={contract ? 'Contrat signé' : 'Nouveau devis'}
      badge={badges}
      leftButton={
        <Button icon="arrow-left-regular" size="small" variant="secondary" onClick={navigateToBackofficeHome} />
      }
      rightButtonGroups={[
        <QuoteEditorV2ButtonBar
          subscriptionId={props.subscriptionId}
          changes={props.changes}
          onReset={props.onReset}
          onValidate={props.onValidate}
          nonDimensionalDataAfter={props.nonDimensionalDataAfter}
          currentEmail={props.currentEmail}
          nextEnabledByAllContributions={props.nextEnabledByAllContributions}
          bypassBrowserConfirmationDialog={props.bypassBrowserConfirmationDialog}
          key="1"
        />,
      ]}
    />
  )
})
