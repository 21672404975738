import type { SubscriptionNonDimensionalData } from '@orus.eu/backend/src/routers/pending-subscriptions'
import { useEnqueueToast } from '@orus.eu/pharaoh'
import { Row } from '@orus.eu/pharaoh/src/components/rows'
import { memo, useCallback } from 'react'
import { copyLinkToClipboardWithAlert } from '../../../../../lib/clipboard-util'
import { assert } from '../../../../../lib/errors'
import { useBuildUrl } from '../../../../../use-build-url'

type QuoteSignSharingRowProps = {
  nonDimensionalData: SubscriptionNonDimensionalData
}

const getToken = (nonDimensionalData: SubscriptionNonDimensionalData): string | undefined => {
  const { quoteSharingStatus, canGenerateAgreedTermsDraft } = nonDimensionalData

  if (quoteSharingStatus.type === 'disabled' || canGenerateAgreedTermsDraft.type === 'cannot-generate') return undefined

  return quoteSharingStatus.token
}

export const QuoteSignSharingRow = memo<QuoteSignSharingRowProps>(function QuoteSignSharingRow({ nonDimensionalData }) {
  const { enqueueToast } = useEnqueueToast()
  const { buildUrl } = useBuildUrl()

  const copyLink = useCallback(() => {
    const token = getToken(nonDimensionalData)
    assert(token, 'token should be defined')

    copyLinkToClipboardWithAlert(
      buildUrl({
        to: '/s/c/$token',
        params: { token },
      }),
    )
    enqueueToast('Lien de signature copié', { variant: 'success' })
  }, [nonDimensionalData, enqueueToast, buildUrl])

  return <Row subtitle="Copier le lien de signature" leftIcon="link-regular" key="1" onClick={copyLink} />
})
